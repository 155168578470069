var splideWrapper = document.getElementById('splide-gallery-wrapper');
if (splideWrapper) {
    splideWrapper.firstChild.classList.add('splide'); //to target the inner-container of the group block
    var splideTrack = document.getElementById('splide-track'); //the <figure> tag of the gallery block
    splideTrack.classList.add('splide__track');
    splideTrack.firstChild.classList.add('splide__list');
    splideSlides = splideTrack.getElementsByTagName('li'); //grab all the <li> tags, and loop through them
    for (var i = 0; i < splideSlides.length; i++) {
        splideSlides[i].classList.add('splide__slide');
    };
}
