var readMoreButton = document.getElementById( 'read-more' )

if ( readMoreButton ) {

    var textExpanded = document.getElementsByClassName( 'collapse-text' );
    textExpanded[0].setAttribute( 'aria-expanded', false );

    readMoreButton.addEventListener('click', () => {

        //var textExpanded = readMoreButton.getAttribute('aria-expanded');

        if ( textExpanded[0].getAttribute('aria-expanded') == 'false' ) {
            textExpanded[0].setAttribute('aria-expanded', true);
            readMoreButton.innerHTML = 'Leer Menos --';
        } else {
            textExpanded[0].setAttribute('aria-expanded', false);
            readMoreButton.innerHTML = 'Leer Mas ++';
        }

    });

}
