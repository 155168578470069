import tippy from 'tippy.js';

tippy('#nivelDesarrollo', {
    theme: 'light-border',
    trigger: 'mouseenter click',
    interactive: true,
    content: '<h6>Solo para usuarios registrados. <strong>Únete:</strong> <a href="/network/#turismoNaturaleza">https://marketreadytourism.org/network/</a><h6>',
    allowHTML: true,
});

tippy('#nivelDesarrolloLoggedIn', {
    theme: 'light-border',
    trigger: 'mouseenter click',
    interactive: true,
    content: '<h6><a href="/home-de-usuario-logeado-empresas/?empresa=' + entryId + '">https://marketreadytourism.org/home-de-usuario-logeado-empresas/?empresa=' + entryId + '</a><h6>',
    allowHTML: true,
});